import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

const NotFoundPage = () => (
  <Layout>
    <div className='box-content max-w-screen-xl mx-auto px-4 md:px-12 xl:px-36 w-[calc(100%-2rem)] md:w-[calc(100%-6rem)] xl:w-[calc(100%-18rem)]'>
      <div className='flex flex-wrap -mb-20 -mx-4 md:-mx-8 xl:-mx-12 2xl:-mx-16 py-20'>
        <div className='mb-20 px-4 md:px-8 xl:px-12 2xl:px-16 w-full md:w-1/2'>
          <h1 className='mb-10'>404: Not Found</h1>
          <p className='mb-5 text-xl'>Sorry, this page doesn&rsquo;t exist.</p>
          <a
            href='/'
            className='font-bold relative text-xl before:absolute before:-bottom-1 before:left-0 before:right-0 before:bg-black before:h-0.5 before:rounded before:origin-top before:transition-transform after:content-["\2192"] after:inline-block after:ml-2 after:transition-transform motion-safe:hover:after:translate-x-2'
          >
            Go to the home page
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export const Head = () => <Seo title='404: Not Found' />;

export default NotFoundPage;
